// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}neighborInteraction/getMemberList`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 删除
const deletedURL = (id) => {
  return `${API_CONFIG.butlerBaseURL}neighborInteraction/deleteMember/${id}`
}
// 导出
const exportListURL = `${API_CONFIG.butlerBaseURL}neighborInteraction/exportSocialMemberList`

// 导入
const importUrl = `${API_CONFIG.butlerBaseURL}neighborInteraction/socialMemberImport`

// 新增页面 - 查询社群信息接口
const getNeighborClubInfo =  `${API_CONFIG.baseURL}serverNeighborAction!neighborEdit.action`

// 获取用户列表
const getOwnerList = `${API_CONFIG.baseURL}serverCodewordAction!getOwners.action`

// 新增页面 - 新增接口
const socialMember =  `${API_CONFIG.butlerBaseURL}neighborInteraction/socialMember`

// 审核页面 - 获取审核成员信息接口
const socialMemberDateilurl = `${API_CONFIG.butlerBaseURL}neighborInteraction/socialMemberDateil`

// 审核页面- 审核接口
const socialMemberExamineUrl = `${API_CONFIG.butlerBaseURL}neighborInteraction/socialMemberExamine`

export {
  getListURL,
  getOrgListURL,
  deletedURL,
  exportListURL,
  getNeighborClubInfo,
  getOwnerList,
  socialMember,
  importUrl,
  socialMemberDateilurl,
  socialMemberExamineUrl,
}
