<template>
  <div class="container">
    <!-- 审核 -->
    <form-panel
      v-bind="submitConfig"
      ref="formPanel"
      :form="form"
      :submitBefore="submitBefore"
    >
      <col2-detail>
        <col2-block>
            <el-form-item
                label="所属公司"
                :rules="[
                { required: true, message: '所属公司不能为空', trigger: 'blur' },
                ]"
                prop="regionName"
            >
                <span>{{ form.regionName }}</span>
            </el-form-item>

            <el-form-item
                label="所属项目"
                :rules="[
                { required: true, message: '所属项目不能为空', trigger: 'blur' },
                ]"
                prop="communityName"
            >
                <span>{{ form.communityName }}</span>
            </el-form-item>

            <el-form-item
                label="社群名称"
                :rules="[
                { required: true, message: '社群名称不能为空', trigger: 'blur' },
                ]"
                prop="groupName"
            >
                <span>{{ form.groupName }}</span>
            </el-form-item>

            <el-form-item
                label="职位"
                :rules="[
                { required: true, message: '职位不能为空', trigger: 'blur' },
                ]"
                prop="userRole"
            >
                <span>{{ userRoleOpss[form.userRole] }}</span>
            </el-form-item>

            <el-form-item
                label="用户姓名"
                :rules="[
                { required: true, message: '用户姓名不能为空', trigger: 'blur' },
                ]"
                prop="userName"
            >
                <span>{{ form.userName }}</span>
            </el-form-item>

            <el-form-item
                label="昵称"
                :rules="[
                { required: true, message: '昵称不能为空', trigger: 'blur' },
                ]"
                prop="userNickName"
            >
                <span>{{ form.userNickName }}</span>
            </el-form-item>

            <el-form-item
                label="房号信息"
                :rules="[
                { required: true, message: '房号信息不能为空', trigger: 'blur' },
                ]"
                prop="houseInfo"
            >
                <span>{{ form.houseInfo }}</span>
            </el-form-item>

            <el-form-item
                label="身份"
                :rules="[
                { required: true, message: '身份不能为空', trigger: 'blur' },
                ]"
                prop="userType"
            >
                <span>{{ userTypeOpss[form.userType] }}</span>
            </el-form-item>

            <el-form-item
                label="申请理由"
                prop="userReason"
            >
                <span>{{ form.userReason }}</span>
            </el-form-item>

            <el-form-item
                label="状态"
                :rules="[
                { required: true, message: '请选择状态', trigger: 'blur' },
                ]"
                prop="status"
            >
                <v-select
                  v-model="form.status"
                  :options="StatusOpss"
                  :width="140"
                />
            </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { Col2Detail, Col2Block } from "components/bussiness";
import { socialMemberDateilurl, socialMemberExamineUrl } from "./api";
import { userRole, userType, auditStatusSelectOps } from './map'

export default {
  name: "MemberAduit",
  components: {
    Col2Detail,
    Col2Block,
  },
  data() {
    return {
      form: {
        status: 1
      },
      submitConfig: {
        submitUrl: socialMemberExamineUrl,
        submitContentType: "application/json",
        submitMethod: "PUT",
      },
      StatusOpss: auditStatusSelectOps,
      userRoleOpss: userRole,
      userTypeOpss: userType,
      groupId: undefined
    };
  },
  created() {
    const { neighborClubId, companyName } = this.$route.query;
    this.groupId = neighborClubId;
    this.getSocialMemberDateilFun(neighborClubId);
    this.form.companyName = companyName;
  },
  methods: {
    submitBefore(data) {
      delete data.communityName
      delete data.groupName
      delete data.houseInfo
      delete data.regionName
      delete data.userName
      delete data.userNickName
      delete data.userReason
      delete data.userRole
      delete data.userType

      data.id = this.groupId

      console.log(data, '++++data----')
      return true;
    },
    getSocialMemberDateilFun(id) {
      let _this = this;
      this.$axios.get(socialMemberDateilurl + `?id=${id}`).then((res) => {
        if (res.status == "100") {
            console.log('++++res', res.data)
            const data = res.data;
            _this.form = data
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
