var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属公司",
                        rules: [
                          {
                            required: true,
                            message: "所属公司不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "regionName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.regionName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "所属项目不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "communityName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.communityName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "社群名称",
                        rules: [
                          {
                            required: true,
                            message: "社群名称不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "groupName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.groupName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "职位",
                        rules: [
                          {
                            required: true,
                            message: "职位不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "userRole",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.userRoleOpss[_vm.form.userRole])),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户姓名",
                        rules: [
                          {
                            required: true,
                            message: "用户姓名不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "userName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.userName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "昵称",
                        rules: [
                          {
                            required: true,
                            message: "昵称不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "userNickName",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.userNickName))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "房号信息",
                        rules: [
                          {
                            required: true,
                            message: "房号信息不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "houseInfo",
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.form.houseInfo))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "身份",
                        rules: [
                          {
                            required: true,
                            message: "身份不能为空",
                            trigger: "blur",
                          },
                        ],
                        prop: "userType",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.userTypeOpss[_vm.form.userType])),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "申请理由", prop: "userReason" } },
                    [_c("span", [_vm._v(_vm._s(_vm.form.userReason))])]
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "状态",
                        rules: [
                          {
                            required: true,
                            message: "请选择状态",
                            trigger: "blur",
                          },
                        ],
                        prop: "status",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.StatusOpss, width: 140 },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }